ProfessionalFieldModule
<template>
  <multiselect
    v-model="form.professional_selected"
    id="professional_selected"
    :options="professionalOptions"
    @select="onProfessionalSelect"
    label="label"
    track-by="value"
    :allow-empty="false"
    :option-height="40"
    :showLabels="false"
    placeholder="Selecionar"
    class="with-border"
    :disabled="readonly"
    :key="reloadValues"
  >
    <template slot="option" slot-scope="{ option }">
      <div class="custom-option">
        <div class="truncate">
          <span class="d-block">{{ option.label }}</span>
          <p>
            <span class="text-blue">
              {{ option?.professionalData?.council.replace(/\D/g, '') }} -
              {{ option?.professionalData?.councilNumber }}
            </span>
            |
            {{ option?.professionalData?.cbo }}
          </p>
        </div>
      </div>
    </template>
    <template slot="caret">
      <div class="chevron">
        <ChevronDown />
      </div>
    </template>
    <template slot="noOptions">
      Nenhum profissional cadastrado, atualize a configuração do convênio
    </template>
    <template slot="noResult">Nenhum profissional encontrado</template>
  </multiselect>
</template>

<script>
export default {
  name: 'ProfessionalFieldModule',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  props: {
    form: Object,
    settingsId: String,
    multiselect: Boolean,
    readonly: {
      type: Boolean || Number,
      default: false
    },
    fillProfessionalData: Function, 
    reloadValues: Number
  },
  async created() {
    await this.getProfessionals()
  },
  data() {
    return {
      cbos: require('@/components/Tiss/Data/cbos.json'),
      professionalCouncils: require('@/components/Tiss/Data/professionalCouncils.json'),
      invoicingTissSettingProfessional: null,
      professionalsArray: [],
      professionalOptions: []
    }
  },
  methods: {
    async getProfessionals() {
      if (!this.settingsId || this.professionalOptions.length) return
      try {
        const { data } = await this.api.getInvoicingTissSettingProfessionals(
          this.settingsId
        )

        this.professionalsArray = data
        this.professionalOptions = data.map(item => {
          const {
            cbo,
            council,
            professional_number: councilNumber
          } = item?.professional?.tiss_professional_setting
          return {
            label: item.professional.name,
            value: item.professional.id,
            professionalData: {
              cbo: this.cbos.find(option => option.value === cbo)?.label,
              council: this.professionalCouncils.find(
                option => option.value === council
              )?.label,
              councilNumber
            }
          }
        })

        this.$emit('change-professionals', this.professionalsArray)
        if (!this.professionalOptions.length)
          this.$toast.warning(
            `Não existe profissionais ativos neste convênio! \n Atualize-o na configuração!`
          )
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onProfessionalSelect($event) {
      const id = $event.value
      const invoicingTissSettingProfessional = this.professionalsArray.find(
        item => item.professional.id === id
      ).professional
      this.form.professional_selected = this.professionalOptions.find(
        option => option.value === id
      )
      this.reloadValues++

      this.fillProfessionalData(invoicingTissSettingProfessional)
    }
  },
  watch: {
    settingsId: {
      handler(value) {
        if (!value) return
        this.getProfessionals()
      },
      immediate: true
    },
    'form.professional_id': {
      handler(value) {
        if (!value) return
        this.form.professional_selected = this.professionalOptions.find(
          option => option.value === value
        )      },
      immediate: true
    },
    professionalOptions: {
      handler(value) {
        if (!value?.length || !this.form.professional_id) return
        this.form.professional_selected = value.find(
          option => option.value === this.form.professional_id
        )
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.option {
  width: 90%;
}
</style>
